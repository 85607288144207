import React, { useState, useCallback } from 'react';
import { Container, Box, TextField, Button, Typography, Alert, Card, CardContent, CardActionArea } from '@mui/material';
import Confetti from 'react-confetti';
import Particles from "react-tsparticles";
import { loadStarsPreset } from "tsparticles-preset-stars";
import SVGPlanet from "./SVGPlanet";
import SVGAstronaut from "./SVGAstronaut";
import "./style.css";

const MultiplicationGame = () => {
  const [number1, setNumber1] = useState(Math.ceil(Math.random() * 10));
  const [number2, setNumber2] = useState(Math.ceil(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadStarsPreset(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const options = {
    // preset: "stars",
    background: {
      color: "#fff"
    },
    particles: {
      color: { value: "#000" },
      move: {
        direction: "none",
        enable: true,
        outModes: "out",
        random: true,
        speed: 0.05
      },
      number: {
        density: {
          enable: true
        },
        value: 600
      },
      opacity: {
        animation: {
          enable: true,
          speed: 5
        },
        value: { min: 0.3, max: 0.6 }
      },
      shape: {
        type: "circle"
      },
      size: {
        value: 2
      }
    },
    size: {
      value: { min: 0.05, max: 0.15 }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctAnswer = number1 * number2;
    if (parseInt(userAnswer) === correctAnswer) {
      setFeedback(<Alert severity="success">Correct! Great job!.</Alert>);
      setCorrectAnswers(correctAnswers + 1);
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false); // First, turn off the confetti
        setFeedback(''); // Then, clear the feedback message
        // Change question after correct answer
        setNumber1(Math.ceil(Math.random() * 10));
        setNumber2(Math.ceil(Math.random() * 10));
      }, 3000); // Do this after a delay of 2 seconds
    } else {
      setFeedback(<Alert severity="error">Oops! Try again.</Alert>);
      setIncorrectAnswers(incorrectAnswers + 1);
    }
    setUserAnswer('');
  };

  // Generate the visual grid of balls
  const generateBallsGrid = () => {
      let grid = [];

      // Add the first row (header for columns)
      let firstRow = [<Box key="corner" style={{ width: 45, height: 30, mx: 1, visibility: 'hidden' }}></Box>]; // Placeholder for the top-left corner
      for (let col = 1; col <= number1; col++) {
        firstRow.push( // First row will display numbers
          <Box key={`col-header-${col}`} width={30} height={30} bgcolor="#ffff99" display="flex" justifyContent="center" alignItems="center" mx={1}>
            <Typography variant="body2">{col}</Typography>
          </Box>
        );
      }
      grid.push(<Box key="first-row" display="flex" justifyContent="center" my={1}>{firstRow}</Box>);

      // Add remaining rows with row headers
      for (let row = 1; row <= number2; row++) { // First col will display numbers
        let rowItems = [
          <Box key={`row-header-${row}`} width={30} height={30} bgcolor="#ffff99" display="flex" justifyContent="center" alignItems="center" mx={1}>
            <Typography variant="body2" align="center">{row}</Typography>
          </Box>
        ]; // First item is the row header

        for (let col = 1; col <= number1; col++) {
          rowItems.push(
            <Box
              key={`ball-${row}-${col}`}
              bgcolor="#90caf9"
              width={30}
              height={30}
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mx={1}
            />
          );
        }

        grid.push(<Box key={`row-${row}`} display="flex" justifyContent="center" my={1}>{rowItems}</Box>);
      }

      return grid;
  };

  const totalQuestions = correctAnswers + incorrectAnswers;
  const percentageCorrect = totalQuestions > 0 ? Math.round((correctAnswers / totalQuestions) * 100) : 0;

  return (
    <Container maxWidth="sm">
      <Particles
        style={{zIndex:2, position: "relative"}}
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={options}
      />
      <SVGPlanet />
      {showConfetti && <Confetti />}
        <Typography variant="h4" gutterBottom style={{zIndex:4, position: "relative"}}>
          What is {number1} x {number2}?
        </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          style={{backgroundColor:"#fff"}}
          label="Your Answer"
          variant="outlined"
          type="number"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
        <div style={{display: "flex"}}>
          <Typography variant="subtitle1" gutterBottom color={"#ab003c"} style={{zIndex:4, position: "relative"}}>
            {feedback}
          </Typography>
          <SVGAstronaut />
        </div>
      </form>
      <Box display="flex" flexDirection="column" alignItems="center" my={2} style={{zIndex:4, position: "relative"}}>
        {generateBallsGrid()}
      </Box>
      <Card style={{marginTop:"10px", zIndex:4, position: "relative", backgroundColor: "#fff"}}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color={"#ab003c"}>Statistics [{percentageCorrect}%]</Typography>
            <Typography variant="body2" color="text.secondary">Correct Answers: {correctAnswers}</Typography>
            <Typography variant="body2" color="text.secondary">Incorrect Answers: {incorrectAnswers}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Container>
  );
};

export default MultiplicationGame;
