import React from 'react';
import MultiplicationGame from './MultiplicationGame';
import { CssBaseline, Container, Box, Typography } from '@mui/material';

function App() {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box display="flex" alignItems="center" gap={2} marginTop={2} marginBottom={2} justifyContent="center" style={{zIndex:4, position: "relative"}}>
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Multiplication Game Logo" style={{ height: '50px' }} />
          <Typography variant="h4" component="h1">Multiplication Game for Kids</Typography>
        </Box>
        <MultiplicationGame />
        <footer style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '20px' }}>
          <Typography variant="body1">
            All rights reserved nir-mizrahi © 2024
          </Typography>
        </footer>
      </Container>
    </>
  );
}

export default App;
